function focusIndicator() {
  const focusAttr = 'data-focus-method'
  let focusMethod
  let lastFocusMethod

  document.body.addEventListener('touchstart', () => {
    focusMethod = 'touch'
  })

  document.body.addEventListener('mousedown', () => {
    if (focusMethod === 'touch') {
      return
    }
    focusMethod = 'mouse'
  })

  document.body.addEventListener('keydown', () => {
    focusMethod = 'key'
  })

  document.body.addEventListener('focusin', (event) => {
    if (focusMethod === 'key') {
      // @ts-expect-error
      const elementName = event.target.nodeName.toLowerCase()
      console.log('elementName', elementName)
      if (
        (elementName !== 'input' &&
          elementName !== 'textarea' &&
          !(event.target as Element)?.classList.contains('fr-view')) ||
        focusMethod === lastFocusMethod
      ) {
        // @ts-expect-error
        event.target.setAttribute(focusAttr, focusMethod)
      }
    }
  })

  document.body.addEventListener('focusout', (event) => {
    // @ts-expect-error
    const elementName = event.target.nodeName.toLowerCase()
    // @ts-expect-error
    event.target.removeAttribute(focusAttr)

    if (
      elementName !== 'input' &&
      elementName !== 'textarea' &&
      !(event.target as Element)?.classList.contains('fr-view')
    ) {
      lastFocusMethod = focusMethod
    }
  })
}

export default focusIndicator
