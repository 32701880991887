const expandedClass = 'expanded'

function getToggleCallback($elm: JQuery<HTMLElement>): any {
  return KUNDO.utils.getFunctionByName($elm.attr('data-toggle-callback') as string)
}

export const Toggle = (function () {
  let animationTime = 0 // for widescreen change on init
  let isInitialized = false

  return {
    toggle(elm, toggleFunction) {
      const target = KUNDO.toggle.getTargetElement(elm)
      if (elm.attr('data-toggle-once') === 'true' && target.is('.expanded')) {
        return false
      }

      elm.toggleClass(expandedClass)
      target.toggleClass(expandedClass)

      if (elm.hasClass(expandedClass)) {
        elm.attr('aria-expanded', 'true')
      } else {
        elm.attr('aria-expanded', 'false')
      }

      const callback = getToggleCallback(elm)

      toggleFunction.call(target, animationTime, callback)
      return false
    },

    toggleAccordion() {
      const elm = $(this)
      const wrapper = elm.closest('.fn-toggle-accordion-wrapper')
      const targetSelector = wrapper.attr('data-toggle-target')
      const target = elm.parent().parent().find(`.${targetSelector}`)
      const others = wrapper.find(`.${targetSelector}`).not(target)
      const callback = getToggleCallback(wrapper)

      target.slideToggle(animationTime / 2, callback)
      others.slideUp(animationTime / 2)
      return false
    },

    toggleClass(evt) {
      // This allows requests to be sent when clicking menu options in email headers
      if (
        evt.target.classList.contains('admin-entry-actions__action-list-action') ||
        evt.target.classList.contains('admin-entry-actions__item-icon') ||
        evt.target.classList.contains('admin-entry-actions__item-label')
      ) {
        return true
      }

      evt.preventDefault()
      const elm = $(this)
      if (elm.attr('data-toggle-once') === 'true' && elm.is('.expanded')) {
        return true
      }

      const target = KUNDO.toggle.getTargetElement(elm)
      elm.toggleClass(expandedClass)
      target.toggleClass(elm.attr('data-toggle-classname'))

      const callback = getToggleCallback(elm)
      if (callback) {
        callback.call(elm)
      }
    },

    getTargetElement(elm) {
      const selector = elm.attr('data-toggle-target')
      if (!selector) {
        return elm
      }

      if (selector === 'parent') {
        return elm.parent()
      }

      let target = elm.parent().find(`.${selector}`)
      if (target.length) {
        return target
      }

      target = elm.parent().find(`${selector}`)
      if (target.length) {
        return target
      }

      target = elm.closest('form').find(`.${selector}`)
      if (target.length) {
        return target
      }

      target = elm.closest('div').find(`.${selector}`)
      if (target.length) {
        return target
      }

      target = $(`.${selector}`)
      return target.length ? target : elm
    },

    resetToggleTargets(elms) {
      if (elms) {
        elms.each(function () {
          const target = KUNDO.toggle.getTargetElement($(this))
          target.attr('style', '')
        })
      }
    },

    destroy(destroyMobile) {
      if (!isInitialized) {
        return
      }
      if (destroyMobile) {
        const elements = $('.fn-mobile-toggle')
        KUNDO.toggle.resetToggleTargets(elements)
        elements.off('click')
      }
    },

    addAriaAttributes() {
      document.querySelectorAll('.fn-mobile-toggle[data-toggle-target="links"]').forEach((elm) => {
        const target = elm.parentNode?.parentNode?.querySelector('.links')

        if (target) {
          elm.setAttribute('role', 'button')
          elm.setAttribute('aria-controls', target.id)

          if (target.classList.contains('expanded')) {
            elm.setAttribute('aria-expanded', 'true')
          } else {
            elm.setAttribute('aria-expanded', 'false')
          }
        }
      })
    },

    deleteAriaAttributes() {
      document.querySelectorAll('.fn-mobile-toggle[data-toggle-target="links"]').forEach((elm) => {
        const target = elm.parentNode?.parentNode?.querySelector('.links')

        elm.removeAttribute('role')
        elm.removeAttribute('aria-controls')
        elm.removeAttribute('aria-expanded')
      })
    },

    init(isMobile?: boolean) {
      if (isMobile) {
        animationTime = 0

        KUNDO.toggle.addAriaAttributes()

        $('.fn-mobile-toggle').click(function (event) {
          event.preventDefault()
          KUNDO.toggle.toggle($(this), $.fn.fadeToggle)
        })
      } else {
        animationTime = 100

        KUNDO.toggle.deleteAriaAttributes()
      }

      if (!isInitialized) {
        $('.fn-toggle').click(function () {
          KUNDO.toggle.toggle($(this), $.fn.fadeToggle)
        })
        $('.fn-toggle-class').click(this.toggleClass)
        $('.fn-toggle-accordion').click(KUNDO.toggle.toggleAccordion)
        isInitialized = true
      }
    },
  }
})()

KUNDO.toggle = Toggle
